/*!
 * jquery.numscroll.js -- 数字滚动累加动画插件  (Digital rolling cumulative animation)
 * version 1.0.0
 * 2018-09-22
 * author: KevinTseng < 921435247@qq.com@qq.com >
 * 文档:  https://github.com/chaorenzeng/jquery.numscroll.js.git
 * QQ交流群: 739574382
 */

(function ($) {

    /**
     * Check, if the number is Integer
     *
     * @param num
     * @returns {boolean}
     */
    function isInt(num) {

        let isInteger = false;

        try {
            if (!(String(num).includes('.') && String(num).includes(','))) {
                isInteger = parseInt(num) % 1 === 0;
            }
        } catch (e) {
            /** No need to change the value of the `isInteger` on a failure **/
        }

        return isInteger;
    }

    /**
     * Check, if the number is float/double/contains decomal
     *
     * @param num
     * @returns {number}
     */
    function isFloat(num) {
        let decimalPlaces = -1;

        try {
            if (!String(num).includes(".")) {
                let index = String(num).indexOf(".") + 1, // Get the position of the decimal point
                    count = String(num).length - index; // Get the number after the decimal point

                decimalPlaces = index > 0 ? count : decimalPlaces;
            }
        } catch (e) {
            /** No need to change the value of the `isInteger` on a failure **/
        }

        return decimalPlaces;
    }

    /**
     * Function constructor
     *
     * @param options
     * @returns {*}
     */
    $.fn.numScroll = function (options = {}) {

        let settings = $.extend({
            time: 1500,
            delay: 0
        }, options);

        return this.each(function () {
            let element = $(this),
                $settings = settings;

            let parsedValue = element.attr("data-num") || element.text(), // Get digit from the element data attribute or inner text
                initialValue = 0, // Initial value
                step = (parsedValue / $settings.time) * 10; // Step size

            let numberType = isInt(parsedValue) ? 'integer' : isFloat(parsedValue);
            element.text(initialValue);

            setTimeout(function () {
                let numScroll = setInterval(function () {

                    switch (numberType) {
                        case 'integer':
                            element.text(Math.floor(initialValue));
                            break;
                        case -1:
                            element.text(initialValue.toFixed(numberType));
                            break;
                        default:
                            element.text(parsedValue);
                            break;
                    }

                    initialValue += step;

                    if (initialValue > parsedValue) {
                        element.text(parsedValue);
                        clearInterval(numScroll);
                    }

                }, 1);
            }, $settings.delay);

        });
    };

})(jQuery);